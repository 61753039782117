import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useForwardedRef from '@bedrock-layout/use-forwarded-ref';

const className = 'HighlightWithinTextarea';

const specialSpace = ' ';

const Editor = styled.div`
  border: 1px solid gray;
  border-radius: 5px;
  height: 3em;
  text-align: left;
  display: inline-block;
  position: relative;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  resize: none;
  background: transparent;
  padding: 4px;
`;

const Mentions = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 70%;
  padding: 0.1em 0.4em;
  background: lightgray;
  border-top-left-radius: 4px;
`;

const Suggestions = styled.div`
  border: 1px solid lightblue;
  background: white;
  z-index: 1;
  border-top: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: none;
  flex-wrap: wrap;
`;

const SuggestionsOption = styled.button`
  padding: 0.2em 1em;
  background: transparent;
  border: 0;
  border-top: 1px solid lightblue;
  flex: 1 0 auto;
  max-width: 100%;
  width: 100%;
  text-align: left;

  :hover {
    background: lightblue;
    cursor: pointer;
  }
`;

const NoteInput: React.FC<Props> = forwardRef<HTMLTextAreaElement, Props>(({ style, onChange, onMentionsChange, onSuggest, ...props }, ref) => {
  const [candidate, setCandidate] = useState<string | null>(null);
  const [mentions, setMentions] = useState<Array<string>>([]);

  const onMentionsChangeRef = useRef(onMentionsChange);

  const editorRef = useForwardedRef<HTMLTextAreaElement>(ref);

  useEffect(() => {
    onMentionsChangeRef.current?.(mentions);
  }, [mentions]);

  const updateMentionsList = () => {
    if (!editorRef.current) return;
    const textMentions = editorRef.current.value.match(/@[\w ]+/g) || [];
    const updatedMentions = textMentions
      .map((mention) => mention.trim().slice(1))
      .filter(
        (mention, index, list) => mention && list.indexOf(mention) === index
      )
      .sort();
    if (
      updatedMentions.length !== mentions.length ||
      updatedMentions.some((mention) => !~mentions.indexOf(mention))
    ) {
      setMentions(updatedMentions);
    }
  };

  const getSelectedMention = () => {
    const editor = editorRef.current;

    if (!editor) return null;

    const text = editor.value;
    const lastAtPosition = text
      .slice(0, editor.selectionStart)
      .lastIndexOf('@');

    if (lastAtPosition < 0) return null;

    const [selectedMention] =
      text.slice(lastAtPosition + 1).match(/^[\w ]+/) || [];

    if (
      !selectedMention ||
      selectedMention.length < editor.selectionStart - lastAtPosition - 1
    )
      return null;

    return {
      value: selectedMention,
      start: lastAtPosition,
      end: lastAtPosition + selectedMention.length + 1
    };
  };

  const replaceContent = (newContent: string, newStartPosition?: number, newEndPosition?: number) => {
    if (!editorRef.current) return;
    const start =
      typeof newStartPosition !== 'undefined'
        ? newStartPosition
        : editorRef.current.selectionStart;
    const end =
      typeof newEndPosition !== 'undefined'
        ? newEndPosition
        : editorRef.current.selectionEnd;

    editorRef.current.value = newContent || '';
    editorRef.current.selectionStart = start;
    editorRef.current.selectionEnd = end;
    onChange?.(newContent || '');
  };

  const handleCursorPositionChange = useCallback(() => {
    const selectedMention = getSelectedMention();
    if (selectedMention) setCandidate(selectedMention.value.trim());
    else setCandidate(null);
  }, []);

  const onKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { currentTarget, key } = event;
    const start = currentTarget.selectionStart;

    // No need for modification when command keys pressed
    if (key.length > 1) return;

    // No modifications for non-mention sequences
    if (!currentTarget.value.slice(0, start - 1).match(/@[\w ]+$/)) return;

    if (key === ' ') {
      if (currentTarget.value[start - 2] === specialSpace) {
        // Replace 2 special spaces with 1 normal
        replaceContent(
          currentTarget.value.slice(0, start - 2) + currentTarget.value.slice(start - 1),
          currentTarget.selectionEnd - 1,
          currentTarget.selectionEnd - 1
        );
      } else {
        // Replace 1 normal space with 1 special space if you are in mention
        replaceContent(currentTarget.value.slice(0, start - 1) + specialSpace + currentTarget.value.slice(start));
      }
    } else if (currentTarget.value[start - 2] === specialSpace && !key.match(/[\w ]/)) {
      // Replace special space with normal one
      replaceContent(
        currentTarget.value.slice(0, start - 2) + ' ' + currentTarget.value.slice(start - 1),
        currentTarget.selectionEnd,
        currentTarget.selectionEnd
      );
    }
  };

  const handleChange = ({ currentTarget }: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(currentTarget.value);
    updateMentionsList();
  };

  const completeMention = (mention: string) => {
    if (!editorRef.current) return;

    const selectedMention = getSelectedMention();
    if (!selectedMention) return;

    const firstPart = editorRef.current.value.slice(0, selectedMention.start);
    const lastPart = editorRef.current.value.slice(selectedMention.end);
    const shouldAddSpace = !lastPart.startsWith(" ");
    const newContent = firstPart + `@${mention}${shouldAddSpace ? " " : ""}` + lastPart;
    const newCursorPosition = selectedMention.start + mention.length + 2;
    replaceContent(newContent, newCursorPosition, newCursorPosition);
    setCandidate(null);
    editorRef.current.focus();
    updateMentionsList();
  };

  return (
    <Editor style={style} className={className}>
      <Textarea
        {...props}
        ref={editorRef}
        onKeyUp={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
          onKeyUp(event);
          handleCursorPositionChange();
        }}
        onChange={handleChange}
        onBlur={() => setTimeout(setCandidate, 90, null)}
        onClick={handleCursorPositionChange}
      />
      {mentions.length ? (
        <Mentions>
          {mentions.length} mention{mentions.length > 1 && 's'}
        </Mentions>
      ) : null}
      {candidate && (
        <Suggestions>
          {!mentions.includes(candidate) ? (
            <SuggestionsOption onClick={() => completeMention(candidate)}>
              {candidate}
            </SuggestionsOption>
          ) : null}
          {mentions
            .filter((mention) => mention.startsWith(candidate))
            .map((mention) => (
              <SuggestionsOption key={mention} onClick={() => completeMention(mention)}>
                {mention}
              </SuggestionsOption>
            ))}
        </Suggestions>
      )}
    </Editor>
  )
});

export default NoteInput;

export type Props = {
  value?: string,
  placeholder?: string,
  style?: React.CSSProperties,
  onChange?: (value: string) => void,
  onMentionsChange?: (mentions: Array<string>) => void,
  onSuggest?: (suggestions: string[]) => void,
};