import React from 'react';;
import useAuth from '../hooks/useAuth';
import CenterLayout from './CenterLayout';
import ClockSpinner from './ClockSpinner';
import prevent from '../lib/preventEvent';

const RequireAuthentication: React.FC = ({ children }) => {
  const { currentUser, loading, signInViaGooglePopup } = useAuth();

  if (loading) return <CenterLayout><ClockSpinner /></CenterLayout>;
  if (currentUser) return <React.Fragment>{children}</React.Fragment>;

  return (
    <CenterLayout>
      <p>You need to <a href="#" onClick={prevent(signInViaGooglePopup)}>Sign In</a>.</p>
    </CenterLayout>
  );
};

export default RequireAuthentication;