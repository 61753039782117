import { QueryDocumentSnapshot, SnapshotOptions } from '../hooks/useFirestoreCollectionReference';

export default <Data, Patch, DataToFirestore, DataFromFirestore>({
  toFirestore,
  fromFirestore,
}: FirestoreConverter<Data, Patch, DataToFirestore, DataFromFirestore>) => ({
  toFirestore,
  fromFirestore: (snapshot: QueryDocumentSnapshot<DataFromFirestore>, options?: SnapshotOptions) => ({
    ...fromFirestore(snapshot, options),
    Id: snapshot.id
  }),
});

export interface FirestoreConverterTo<Data, Patch, DataToFirestore> {
  (data: Data | Patch): DataToFirestore;
}

export interface FirestoreConverterFrom<Data, DataFromFirestore> {
  (snapshot: QueryDocumentSnapshot<DataFromFirestore>, options?: SnapshotOptions): Data;
}

export type FirestoreConverter<Data, Patch, DataToFirestore, DataFromFirestore> = {
  toFirestore: FirestoreConverterTo<Data, Patch, DataToFirestore>,
  fromFirestore: FirestoreConverterFrom<Data, DataFromFirestore>,
};