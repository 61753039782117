import { useState, useEffect, useMemo } from 'react';
import useMainFirebase from './useMainFirebase';
import useFirestoreCollectionReference, { CollectionQuery, CollectionReference } from './useFirestoreCollectionReference';
import { FirestoreConverter } from '../lib/createFirestoreConverter';

export default <Data, Patch, DataToFirestore, DataFromFirestore>(
  collectionName: string,
  converter?: FirestoreConverter<Data, Patch, DataToFirestore, DataFromFirestore>,
  query?: Query<Data>,
  withData: boolean = true,
) => {
  const firebaseApp = useMainFirebase();
  const sourceCollection = useFirestoreCollectionReference<Data>(collectionName, firebaseApp);
  const collection = useMemo(() => {
    if (!converter) return sourceCollection;
    return sourceCollection.withConverter(converter);
  }, [sourceCollection, converter]);

  const [documents, setDocuments] = useState<Data[]>();
  useEffect(() => {
    if (!withData) return;
    return (query ? query(collection) : collection).onSnapshot(snapshot => {
      setDocuments(snapshot.docs.map(documentSnapshot => ({ ...documentSnapshot.data(), Id: documentSnapshot.id })));
    });
  }, [collection, query, withData]);

  const save = (data: Patch & Partial<ModelMeta>) =>
    ('Id' in data ? collection.doc(data.Id) : collection.doc()).set(data as unknown as Data);

  const remove = (id: string) => collection.doc(id).delete();

  return { documents, save, remove };
};

export interface Query<Data> {
  (collection: CollectionReference<Data>): CollectionReference<Data> | CollectionQuery<Data>,
};

type ModelMeta = { Id: string };
