import { useSyncedLocalStorage } from 'use-synced-local-storage';

const storageName = 'Track.Me/config';

const initialValue: Config = {
  trackLocation: false,
};

const useLocalConfig = (name?: ConfigName): Config | Result<any> => {
  const [config, setConfig] = useSyncedLocalStorage<Config>(storageName, initialValue);
  if (name)
    return [config[name], (newConfig: any) => setConfig({ ...config, [name]: newConfig })];

  return [config, setConfig];
};

export default useLocalConfig as UseLocalConfig;

type ConfigName = 'trackLocation';

type ConfigTrackLocation = boolean;

interface Config {
  trackLocation: ConfigTrackLocation,
};

type Result<T> = [config: T, setConfig: (newConfig: T) => boolean];

interface UseLocalConfig {
  (): Result<Config>,
  (name: 'trackLocation'): Result<ConfigTrackLocation>,
};