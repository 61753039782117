import React, { Children, useEffect, useRef } from 'react';

type Props = {
  reverse?: boolean;
};

const setScrollBottom = (element: HTMLElement, position: number) => {
  element.scrollTop = element.scrollHeight - element.offsetHeight - position;
};

const Scroll: React.FC<Props> = ({ children, reverse = false }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollBottomRef = useRef(0);
  const scrollTopRef = useRef(0);

  useEffect(() => {
    if (reverse && scrollRef.current) {
      setScrollBottom(scrollRef.current, scrollBottomRef.current);
    }
  }, [Children.toArray(children).length]);

  const onScroll = () => {
    if (!scrollRef.current) return;

    if (scrollTopRef.current === scrollRef.current.scrollTop) {
      setScrollBottom(scrollRef.current, scrollBottomRef.current);
    } else {
      const newScrollPosition = scrollRef.current.scrollTop + scrollRef.current.offsetHeight;
      scrollBottomRef.current = scrollRef.current.scrollHeight - newScrollPosition;
    }
  };

  return (
    <div ref={scrollRef} onScroll={reverse ? onScroll : undefined} style={{ overflow: 'auto', maxHeight: '100%' }}>
      {children}
    </div>
  );
};

export default Scroll;