import React from 'react';

type Props = {
  title: string,
  metaTitle?: string,
};

const RecordsListHeader: React.FC<Props> = ({ title, metaTitle }) => (
  <div style={{ background: 'whitesmoke', color: 'gray', padding: '2em 1em 1em' }}>
    {metaTitle ? <div style={{ float: 'right' }}>{metaTitle}</div> : null}
    {title}
  </div>
);

export default RecordsListHeader;