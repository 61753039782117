import React, { useState } from 'react';
import useRecords from '../hooks/useRecords';
import WebPageLayout from './WebPage.layout';
import AppLayout from './App.layout';
import RecordsList from './RecordsList';
import ControlPanel from './ControlPanel';
import RecordEditor from './RecordEditor';
import RequireAuthentication from './RequireAuthentication';
import { Record, RecordPatch } from '../types/Records';

const TrackMeApp: React.FC = () => {
  const { records, save, remove } = useRecords();
  
  const [selectedRecord, editRecord] = useState<Record | RecordPatch | null>(null);

  return (
    <WebPageLayout>
      <RequireAuthentication>
        <AppLayout
          loading={!records}
          bottomWidget={<ControlPanel onCreateRecord={editRecord} />}
          modalWidget={selectedRecord && (
            <RecordEditor
              record={selectedRecord}
              onSave={save}
              onRemove={remove}
              onCancel={() => editRecord(null)}
            />
          )}
        >
          {records && <RecordsList records={records} onSelect={editRecord} onSave={save} />}
        </AppLayout>
      </RequireAuthentication>
    </WebPageLayout>
  );
};

export default TrackMeApp;