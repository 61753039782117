import { useMemo } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

export default <DocumentData = firebase.firestore.DocumentData>(
  collectionName: string,
  firebaseApp?: firebase.app.App
) => (
  useMemo(() => (
    (firebaseApp || firebase.app()).firestore()
      .collection(collectionName) as firebase.firestore.CollectionReference<DocumentData>
  ), [collectionName, firebaseApp])
);

export const { GeoPoint, Timestamp, FieldValue } = firebase.firestore;

export type SnapshotOptions = firebase.firestore.SnapshotOptions;
export type CollectionQuery<DocumentData> = firebase.firestore.Query<DocumentData>;
export type CollectionReference<DocumentData> = firebase.firestore.CollectionReference<DocumentData>;
export type QueryDocumentSnapshot<DocumentData> = firebase.firestore.QueryDocumentSnapshot<DocumentData>;