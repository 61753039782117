import React, { useState } from 'react';
import Scroll from './Scroll';
import RecordsListItem from './RecordsListItem';
import RecordsListHeader from './RecordsListHeader';
import { Record } from '../hooks/useRecords';

const isSameDay = (timestampA: number, timestampB: number) =>
  new Date(timestampA).toLocaleDateString() === new Date(timestampB).toLocaleDateString()

const DISPLAY_COUNT = 100;

const RecordsList: React.FC<Props> = ({ records, onSelect, onSave }) => {
  if (!records.length) {
    return (
      <div style={{
        height: '100%',
        display: 'flex',
        textAlign: 'right',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '40px',
        fontWeight: 'bold',
        color: 'lightgray',
      }}>
        No records<br />yet
      </div>
    );
  }

  const [visibleAmount, setVisibleAmount] = useState(DISPLAY_COUNT);

  const inProgressActivities = records.filter(record => !record.ended);
  const pastActivities = inProgressActivities.length ? records.filter(record => record.ended) : records;
  const visiblePastActivities = pastActivities
    .slice(0 - visibleAmount + (inProgressActivities.length > 0 ? inProgressActivities.length : 0), pastActivities.length);

  return (
    <Scroll reverse>
      {records.length > visiblePastActivities.length + inProgressActivities.length
        ? <div
          style={{
            background: 'whitesmoke',
            color: 'gray',
            padding: '2em 1em',
            textAlign: 'center',
            borderBottom: '1px solid lightgray'
          }}
          onClick={() => setVisibleAmount(visibleAmount + DISPLAY_COUNT)}
        >
          Load more
        </div>
        : null
      }
      {visiblePastActivities.map((record, index, all) => (
        <React.Fragment>
          {index === 0 || !isSameDay(all[index - 1].started, record.started)
            ? <RecordsListHeader
              key={new Date(record.started).toLocaleDateString()}
              title={new Date(record.started).toLocaleDateString()}
            />
            : null
          }
          <RecordsListItem key={record.Id} record={record} onSelect={() => onSelect(record)} />
        </React.Fragment>
      ))}
      {inProgressActivities.length
        ? <RecordsListHeader key="progress" title="In progress" />
        : null
      }
      {inProgressActivities.map(record => (
        <RecordsListItem
          key={record.Id}
          record={record}
          onSelect={() => onSelect(record)}
          onStop={() => onSave({ ...record, ended: Date.now() })}
        />
      ))}
    </Scroll>
  );
};

export default RecordsList;

type Props = {
  records: Record[],
  onSelect: (record: Record) => void,
  onSave: (record: Record) => void,
};