// Source: https://codepen.io/namon/pen/pyPGOM
import React from 'react';
import styled, { keyframes } from 'styled-components';

const spinnerClockMinRotate = keyframes`
  from {
    transform: rotate(0deg) translate(0, -18px);
  }
  to {
    transform: rotate(360deg) translate(0, -18px);
  }
`;

const spinnerClockSecRotate = keyframes`
  from {
    transform: rotate(0deg) translate(0, -22px);
  }
  to {
    transform: rotate(360deg) translate(0, -22px);
  }
`;

const Spinner = styled.div`
  background: none;
  position: relative;
  width: 128px;
  height: 128px;
`;

const Clock = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: white;
  border: 8px solid #2196F3;
  box-sizing: border-box;
`;

const MinHand = styled.div`
  position: absolute;
  width: 8px;
  height: 40px;
  top: 44px;
  left: 60px;
  background: #39444e;
  border-radius: 5px;
  animation: ${spinnerClockMinRotate} 60s linear infinite;
`;

const SecHand = styled.div`
  position: absolute;
  width: 4px;
  height: 48px;
  top: 40px;
  left: 62px;
  background: #b4bec8;
  border-radius: 2px;
  animation: ${spinnerClockSecRotate} 6s linear infinite;
`;

const ClockSpinner: React.FC = () => (
  <Spinner>
    <Clock />
    <MinHand />
    <SecHand />
  </Spinner>
);

export default ClockSpinner;