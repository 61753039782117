import React, { useState, useRef, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import { useGeoWatch } from 'use-geo';
import DateTimeInput from './DateTimeInput';
import NoteInput from './NoteInput';
import useLocalConfig from '../hooks/useLocalConfig';
import { Record, RecordPatch } from '../types/Records';
import filterEvent from '../lib/filterEvent';

import { faStopwatch } from '@fortawesome/free-solid-svg-icons/faStopwatch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledDateTimeInput = styled(DateTimeInput)`
  input {
    width: 100%;
    box-sizing: border-box;
  }
`;

const Section = styled.div<{ spread?: boolean, header?: boolean, width?: number }>`
  padding: 1em 2em;
  box-sizing: border-box;
  position: relative;
  flex: 1 0 auto;

  transition: width .3s ease;
  width: ${props => props.width || 100}%;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.spread ? css`
    display: flex;
    justify-content: space-between;
  ` : ''}

  ${props => props.header ? css`
    font-size: 80%;
  ` : ''}

  &:last-child {
    padding-bottom: 2em;
  }

  border-right: 1px solid lightgray;

  & + & {
    border-top: 1px solid lightgray;
  }
`;

const TimelessCheckboxButton = styled.label`
  position: absolute;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  margin-left: -15px;
  margin-top: 25px;

  background: #efefef;
  border: 1px solid #767676;

  &:hover {
    background: #e5e5e5;
    border-color: #656565;
  }

  input {
    display: none;
  }

  svg {
    color: #00bd00;
  }

  input:checked + svg {
    color: #666;
  }
`;

const RecordEditor: React.FC<Props> = ({ record, onSave, onRemove, onCancel }) => {
  const [trackLocation, setTrackLocation] = useLocalConfig('trackLocation');
  const { position } = useGeoWatch(trackLocation);

  const [patch, setPatch] = useState<Patch>({});
  const patchedRecord = { ...record, ...patch };

  const [suggestions, setSuggestions] = useState<string[]>([]);

  const titleInputRef = useRef<HTMLTextAreaElement>(null);

  useLayoutEffect(() => {
    if (!patchedRecord.note && titleInputRef.current)
      titleInputRef.current?.base?.querySelector('textarea')?.focus(); // Because of react-highlight-within-textarea
  }, []);

  const [timeless, setTimeless] = useState(record.started === record.ended);

  const changeText = (text: string) => setPatch({ ...patch, note: text.replace(/\n+/g, ' ') });
  const changeStartTimestamp = (started: number) => setPatch({ ...patch, started });
  const changeEndTimestamp = (ended: number) => setPatch({ ...patch, ended });

  const remove = () => {
    if ('Id' in record && confirm('Do you want to remove this record?')) {
      onRemove(record.Id);
      onCancel();
  }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newRecord = Object.assign({}, patchedRecord, {
      ...timeless && { ended: patchedRecord.started },
      ...!patchedRecord.LocationOfCreation && {
        LocationOfCreation: position ? position.coords : null,
      },
    });
    onSave(newRecord);
    onCancel();
  };

  return (
    <form onSubmit={onSubmit} style={{ display: 'flex', flexWrap: 'wrap' }}>
      <Section header>{'Id' in record ? 'Edit' : 'Create'}</Section>
      <Section>
        <NoteInput
          placeholder="Describe activity"
          ref={titleInputRef}
          value={patchedRecord.note}
          onChange={changeText}
          onKeyPressCapture={filterEvent<React.KeyboardEvent>(onSubmit, e => e.key === 'Enter')}
          onSuggest={setSuggestions}
          maxLength={500}
          style={{ width: '100%', boxSizing: 'border-box' }}
        />
      </Section>
      {suggestions.map(suggestion => (
        <p key={suggestion}>{suggestion}</p>
      ))}
      <Section width={timeless ? 60 : 50}>
        <StyledDateTimeInput
          required
          timestamp={patchedRecord.started}
          onChange={changeStartTimestamp}
          max={timeless && patchedRecord.ended && patchedRecord.ended - 1e3 || undefined}
        />
      </Section>
      <Section width={timeless ? 40 : 50}>
        <TimelessCheckboxButton>
          <input type="checkbox" checked={timeless} onChange={e => setTimeless(e.target.checked)} />
          <FontAwesomeIcon icon={faStopwatch} fixedWidth style={{ transform: 'scale(1.3)' }} />
        </TimelessCheckboxButton>

        {timeless
          ? <p style={{ color: 'gray' }}>No time duration</p>
          : null}

        {!timeless && patchedRecord.ended
          ? <StyledDateTimeInput
            required
            timestamp={patchedRecord.ended}
            onChange={changeEndTimestamp}
            min={patchedRecord.started && patchedRecord.started + 1e3}
          />
          : null
        }

        {!timeless && !patchedRecord.ended
          ? <input type="button" value="stop timer ⏹️" onClick={() => changeEndTimestamp(Date.now())} />
          : null
        }
      </Section>
      <Section>
        <label style={{ display: 'block' }}>
          <input type="checkbox" checked={trackLocation} onChange={e => setTrackLocation(e.target.checked)} />
          {' '}
          Track location
        </label>
      </Section>

      <Section spread>
        <input type="button" value="Cancel ↩️" onClick={onCancel} />
        {'Id' in record ? <input type="button" value="Remove 🗑️" onClick={remove} /> : null}
        <input type="submit" value="Save ✅" />
      </Section>
    </form>
  );
};

export default RecordEditor;

type Props = {
  record: Record | RecordPatch,
  onSave: (record: RecordPatch) => void,
  onRemove: (id: string) => void,
  onCancel: () => void,
};

type Patch = Partial<Record>;