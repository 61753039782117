import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Normalize as NormalizeStyles } from 'styled-normalize';

const HTMLPageStyles = createGlobalStyle`
  html {
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: black;
  }

  body {
    width: 100%;
    height: 100%;
    background: snow;
    font: 16px/1.15 BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    color: #222;
  }
`;

const Container = styled.div`
  height: 100%;
  max-width: 540px;
  margin: 0 auto;
  overflow: hidden;
  background: white;

  @media (min-width: 542px) {
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    box-shadow: 0px 0px 24px 8px lightgray;
  }
`;

const WebPageLayout: React.FC = ({ children }) => (
  <Container>
    <NormalizeStyles />
    <HTMLPageStyles />
    {children}
  </Container>
);

export default WebPageLayout;