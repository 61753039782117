import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import format from 'date-fns/format';
import extractDaysAndTimeFromMs from '../lib/extractDaysAndTimeFromMs';
import { Record } from '../hooks/useRecords';
import useIntervalValue, { IntervalCallback } from 'use-interval-value'; //TODO: Use a single second-precise interval

const RecordContainer = styled.div<{ ended: boolean }>`
  padding: 1.5em 1em;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  ${props => props.ended ? '' : css`background: snow`}
`;

const RecordTime = styled.time<{ duration?: boolean }>`
  text-align: right;
  display: block;
  white-space: nowrap;

  ${props => props.duration
    ? css`
      font-size: 1.1em;
      font-family: monospace;
    `
    : css`
      font-size: .9em;
      color: gray;
    `
  }
`;

const RecordsListItem: React.FC<Props> = ({ record, onSelect, onStop }) => {
  const countDuration = useCallback<IntervalCallback<string | null>>(clear => {
    if (record.ended) clear();

    if (record.started === record.ended) {
      return null;
    }

    const interval = extractDaysAndTimeFromMs((record.ended || Date.now()) - record.started);
    const timeLeft = `${interval.hours}:${interval.minutes}:${interval.seconds}`;
    return `${interval.days !== '0' ? `${interval.days}d ` : ''}${timeLeft}`;
  }, [record.started, record.ended])

  const duration = useIntervalValue(1e3, countDuration);


  const onStopClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onStop && onStop();
  };

  return (
    <RecordContainer onClick={onSelect} ended={!!record.ended}>
      {record.note
        ? record.note
        : <span style={{ color: 'gray' }}>No description</span>
      }
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <RecordTime>started {format(record.started, 'HH:mm')}</RecordTime>
          <RecordTime duration>{duration || 'Timeless'}</RecordTime>
        </div>
        {!record.ended && onStop
          ? <div style={{ marginLeft: '1em' }}>
            <input type="button" value="⏹️" onClick={onStopClick} />
          </div>
          : null
        }
      </div>
    </RecordContainer>
  );
};

export default RecordsListItem;

type Props = {
  record: Record,
  onSelect: () => void,
  onStop?: () => void,
};