import { useMemo, useState } from 'react';
import firebase from 'firebase/app';

const DEFAULT_APP_NAME = '[DEFAULT]';

const apps: { [name: string]: ExtendedFirebaseApp | undefined } = {};

export default (config: FirebaseConfig, name: FirebaseAppName = DEFAULT_APP_NAME): ExtendedFirebaseApp => {
  const app = apps[name];
  const [isAuthSynced, setSynced] = useState<boolean>(app?.isAuthSynced || false);

  if (!app) {
    const newApp = firebase.initializeApp(config, name);

    const unsubscribe = newApp.auth().onIdTokenChanged(() => {
      setSynced(true);
      unsubscribe();
    });

    apps[name] = Object.assign(newApp, { isAuthSynced });
  }

  return useMemo(() => Object.assign(apps[name], { isAuthSynced }), [isAuthSynced]);
};

export type FirebaseAppName = string;

export interface FirebaseAppExtension {
  isAuthSynced: boolean,
};

export type ExtendedFirebaseApp = firebase.app.App & FirebaseAppExtension;

export interface FirebaseConfig {
  apiKey?: string,
  appId?: string,
  projectId?: string,
  authDomain?: string,
  databaseURL?: string,
  storageBucket?: string,
  messagingSenderId?: string,
  measurementId?: string,
};