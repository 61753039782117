import { useState, useEffect, useMemo } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

export default (onAuthStateChanged?: OnAuthStateChanged, firebaseApp?: firebase.app.App): firebase.auth.Auth => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
  
  const auth = useMemo(() => (firebaseApp || firebase.app()).auth(), [firebaseApp]);

  useEffect(() => setCurrentUser(auth.currentUser), [auth]);

  useEffect(() => (
    auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      onAuthStateChanged && onAuthStateChanged(user);
    })
  ), [onAuthStateChanged, auth]);

  return useMemo(() => ({
    app: auth.app,
    currentUser: auth.currentUser,
    languageCode: auth.languageCode,
    settings: auth.settings,
    tenantId: auth.tenantId,
    applyActionCode: auth.applyActionCode.bind(auth),
    checkActionCode: auth.checkActionCode.bind(auth),
    confirmPasswordReset: auth.confirmPasswordReset.bind(auth),
    createUserWithEmailAndPassword: auth.createUserWithEmailAndPassword.bind(auth),
    fetchSignInMethodsForEmail: auth.fetchSignInMethodsForEmail.bind(auth),
    isSignInWithEmailLink: auth.isSignInWithEmailLink.bind(auth),
    getRedirectResult: auth.getRedirectResult.bind(auth),
    onAuthStateChanged: auth.onAuthStateChanged.bind(auth),
    onIdTokenChanged: auth.onIdTokenChanged.bind(auth),
    sendSignInLinkToEmail: auth.sendSignInLinkToEmail.bind(auth),
    sendPasswordResetEmail: auth.sendPasswordResetEmail.bind(auth),
    setPersistence: auth.setPersistence.bind(auth),
    signInAndRetrieveDataWithCredential: auth.signInAndRetrieveDataWithCredential.bind(auth),
    signInAnonymously: auth.signInAnonymously.bind(auth),
    signInWithCredential: auth.signInWithCredential.bind(auth),
    signInWithCustomToken: auth.signInWithCustomToken.bind(auth),
    signInWithEmailAndPassword: auth.signInWithEmailAndPassword.bind(auth),
    signInWithPhoneNumber: auth.signInWithPhoneNumber.bind(auth),
    signInWithEmailLink: auth.signInWithEmailLink.bind(auth),
    signInWithPopup: auth.signInWithPopup.bind(auth),
    signInWithRedirect: auth.signInWithRedirect.bind(auth),
    signOut: auth.signOut.bind(auth),
    updateCurrentUser: auth.updateCurrentUser.bind(auth),
    useDeviceLanguage: auth.useDeviceLanguage.bind(auth),
    useEmulator: auth.useEmulator.bind(auth),
    verifyPasswordResetCode: auth.verifyPasswordResetCode.bind(auth),
  }), [currentUser, auth]);
};

export const {
  ActionCodeURL,
  AuthCredential,
  EmailAuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  SAMLAuthProvider,
  TwitterAuthProvider,
} = firebase.auth;

export type OnAuthStateChanged = (user: firebase.User | null) => any;