const second = 1e3;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;

/**
 * Extract human friendly time values
 */
const extractDaysAndTimeFromMs = (msLeft: number) => ({
  days: Math.floor(msLeft / day).toString(),
  hours: Math.floor((msLeft % day) / hour).toString().padStart(2, '0'),
  minutes: Math.floor((msLeft % hour) / minute).toString().padStart(2, '0'),
  seconds: Math.floor((msLeft % minute) / second).toString().padStart(2, '0'),
});

export default extractDaysAndTimeFromMs;