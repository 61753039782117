import React from 'react';
import styled, { keyframes } from 'styled-components';
import CenterLayout from './CenterLayout';
import ClockSpinner from './ClockSpinner';

type Props = {
  bottomWidget?: React.ReactNode,
  modalWidget?: React.ReactNode,
  loading?: boolean,
}

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const fadeOut = keyframes`
  from { opacity: 1 }
  to { opacity: 0 }
`;

const AppContainer = styled.div<{ blocked: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(211, 211, 211, .5);
    visibility: ${props => props.blocked ? 'visible' : 'hidden'};
    animation: ${props => props.blocked ? fadeIn : fadeOut} .2s linear;
    transition: visibility .2s linear;
  }
`;

const ModalContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 98%;
  overflow: auto;
  background: #fff;
  box-shadow: 0px 0px 24px 8px lightgrey;
  border-top: 1px solid lightgray;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
`;

const LoaderBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
`;

const AppLayout: React.FC<Props> = ({ children, bottomWidget, modalWidget, loading }) => (
  <AppContainer blocked={!!modalWidget}>
    <div style={{ flex: 1, minHeight: 0 }}>{children}</div>
    {bottomWidget}
    {modalWidget && <ModalContainer>{modalWidget}</ModalContainer>}
    {loading ? <LoaderBackdrop><CenterLayout><ClockSpinner /></CenterLayout></LoaderBackdrop> : null}
  </AppContainer>
);

export default AppLayout;