import React, { useMemo } from 'react';

type Props = {
  timestamp?: number,
  min?: number,
  max?: number,
  required?: boolean,
  className?: string,
  onChange: (newTimestamp: number) => void,
};

const DAY_TIMESTAMP = 1e3 * 60 * 60 * 24;
const TIMEZONE_SHIFT = new Date().getTimezoneOffset() * 1e3*60;

const formatLocaleDate = (timestamp: number) => new Date(timestamp).toLocaleDateString().split('/').reverse().join('-');

const formatLocaleTime = (timestamp: number) => new Date(timestamp).toLocaleTimeString();

const DateTimeInput: React.FC<Props> = ({ timestamp, min, max, required, onChange, className }) => {
  const { date, time } = useMemo(() => {
    const date = {
      value: timestamp ? formatLocaleDate(timestamp): '',
      min: min ? formatLocaleDate(min): '',
      max: max ? formatLocaleDate(max): '',
    };

    const time = {
      value: timestamp ? formatLocaleTime(timestamp): '',
      min: min && date.value === date.min ? formatLocaleTime(min): '',
      max: max && date.value === date.max ? formatLocaleTime(max): '',
    };

    return { date, time: time };
  }, [timestamp, min, max]);

  const { date: dateTimestamp, time: timeTimestamp } = useMemo(() => ({
    date: timestamp ? timestamp - timestamp % DAY_TIMESTAMP : 0,
    time: timestamp ? timestamp % DAY_TIMESTAMP : 0,
  }), [timestamp]);

  const changeDate = (newDate: number) => isNaN(newDate) || onChange(newDate + timeTimestamp);

  const changeTime = (newTime: number) => isNaN(newTime) || onChange(newTime + TIMEZONE_SHIFT + dateTimestamp);
  
  return <div className={className}>
    <input
      type="date"
      required={required}
      value={date.value}
      min={date.min}
      max={date.max}
      onChange={e => changeDate(e.target.valueAsNumber)}
    />
    <input
      type="time"
      step="1"
      required={required}
      value={time.value}
      min={time.min}
      max={time.max}
      onChange={e => changeTime(e.target.valueAsNumber)}
    />
  </div>
};

export default DateTimeInput;