import { useMemo } from 'react';
import useAuth from './useAuth';
import useFirestoreModel, { Query } from './useFirestoreModel';
import { RecordData, RecordPatch, RecordToFirestore, RecordFromFirestore } from '../types/Records';
import createRecordsFirestoreConverter from '../lib/createRecordsFirestoreConverter';

export default (query?: Query<RecordData>) => {
  const uid = useAuth().currentUser?.uid;

  const converter = useMemo(() => createRecordsFirestoreConverter(uid), [uid]);
  const recordsQuery = useMemo<Query<RecordData>>(() => (
    collection => (query ? query(collection) : collection).orderBy('started').where('OwnerId', '==', uid).limit(50)
  ), [uid, query]);

  const recordsModel = useFirestoreModel
    <RecordData, RecordPatch, RecordToFirestore, RecordFromFirestore>
    ('records', converter, recordsQuery, !!uid);

  const save = (record: RecordPatch) => {
    if (!uid) return Promise.reject('Cannot perform modifying action before authentication');
    return recordsModel.save(record);
  };

  const remove = (id: string) => {
    if (!uid) return Promise.reject('Cannot perform modifying action before authentication');
    return recordsModel.remove(id);
  };

  return { records: recordsModel.documents, save, remove };
};