import useMainFirebase from './useMainFirebase';
import useFirebaseAuth, { OnAuthStateChanged, GoogleAuthProvider } from './useFirebaseAuth';

export default (onAuthStateChanged?: OnAuthStateChanged) => {
  const firebaseApp = useMainFirebase();
  const auth = useFirebaseAuth(onAuthStateChanged, firebaseApp);

  return {
    currentUser: auth.currentUser,
    loading: !firebaseApp.isAuthSynced,
    signInViaGooglePopup: () => auth.signInWithPopup(new GoogleAuthProvider()),
    signOut: auth.signOut,
  };
};