import { Timestamp, GeoPoint, FieldValue } from '../hooks/useFirestoreCollectionReference';
import { RecordData, RecordPatch, RecordToFirestore, RecordFromFirestore } from '../types/Records';
import createFirestoreConverter from './createFirestoreConverter';

export default (ownerId: string = '') =>
  createFirestoreConverter<RecordData, RecordPatch, RecordToFirestore, RecordFromFirestore>({
    toFirestore: record => ({
      note: record.note,
      started: record.started,
      ...record.ended && { ended: record.ended },
      ...record.tags && record.tags.length && { tags: record.tags },
      // Meta fields
      OwnerId: record.OwnerId || ownerId,
      ...record.LocationOfCreation && {
        LocationOfCreation: new GeoPoint(record.LocationOfCreation.latitude, record.LocationOfCreation.longitude),
      },
      ...record.TimeOfCreation
        ? { TimeOfCreation: Timestamp.fromMillis(record.TimeOfCreation) }
        : { TimeOfCreation: FieldValue.serverTimestamp() },
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return {
        note: data.note,
        started: data.started,
        ...data.ended && { ended: data.ended },
        tags: data.tags || [],
        // Meta fields
        OwnerId: data.OwnerId,
        TimeOfCreation: data.TimeOfCreation?.toMillis() || Date.now(), // Because server time is not presented in the object before saving
      };
    },
  });