import React from 'react';
import { RecordPatch } from '../types/Records';

type Props = {
  onCreateRecord: (newRecord: RecordPatch) => void,
};

const createBlankRecord = (): RecordPatch => ({ started: Date.now(), note: '' });

const ControlPanel: React.FC<Props> = ({ onCreateRecord }) => {
  return (
    <div style={{ padding: '2em', textAlign: 'right' }}>
      <input type="button" value="➕" onClick={()  => onCreateRecord(createBlankRecord())} />
    </div>
  );
};

export default ControlPanel;